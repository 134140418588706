import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
import Home from '@/views/home';
import MiddlePage from '@/views/middle';
import Login from '@/views/login';
import Login2 from '@/views/login/index2';
import Rights from '@/views/rights';
import DrugActive from '@/views/rights/drugActive';
import RightsScane from '@/views/rightsScane';
import RightsBaiSui from '@/views/rightsBaiSui';
import RightsBaiSuiPlus from '@/views/rightsBaiSuiPlus';
import RightsActive from '@/views/rights/active';
import RightsPerson from '@/views/rights/person';
import RightsPersonAdd from '@/views/rights/personAdd';
import RightsPersonList from '@/views/rights/personList';
import RightsDetail from '@/views/rights/detail';
import showPdf from '@/views/pdf';
import ProductShow from '@/views/rights/productShow';
import ProgressComponent from '@/views/progress';
import ProgressComponent2 from '@/views/progress/step2';
import ProgressComponent2Base from '@/views/progress/step2Base';
import ProgressComponent2GP96 from '@/views/progress/step2GP96';
import ProgressComponent3 from '@/views/progress/step3';
import ProgressComponent3Base from '@/views/progress/step3Base';
import ProgressComponent4 from '@/views/progress/step4';
import AppointmentDetail from '@/views/appointment/detail';
import AppointmentList from '@/views/appointment/list';
import Personal from '@/views/personal';
import Content1 from '@/views/content/content1';
import Content2 from '@/views/content/content2';
import serverPdfBase from '@/views/content/serverPdfBase';
import serverPdfPlus from '@/views/content/serverPdfPlus';
import serverPdfZhizhen from '@/views/content/serverPdfZhizhen';
import serverPdf320Zhizhen from '@/views/content/serverPdf320Zhizhen';
import serverPdf310Zhizhen from '@/views/content/serverPdf310Zhizhen';
import serverPdf10Zhizhen from '@/views/content/serverPdf10Zhizhen';
import serverPdfPlusZhizhen from '@/views/content/serverPdfPlusZhizhen';
import serverPdfBaiSui from '@/views/content/serverPdfBaiSui';

import serverPdfBasePlus from '@/views/content/serverPdfBasePlus';
import serverPdfTcrt from '@/views/content/serverPdfTcrt';
import settleIndex from '@/views/settle/index';
import settleBaiSui from '@/views/settle/baisui';
import settleHuZhao from '@/views/settle/huzhao';
import settleHuLi from '@/views/settle/huli';
import settleYaoWuYou from '@/views/settle/yaowuyou';


import paysuccess from '@/views/settle/paysuccess';
import payWxAlert from '@/views/settle/payWxAlert';
import meetingIndex from '@/views/meeting/index';
import meetingSuccess from '@/views/meeting/success';
import { isWehat, onLogin,onMobileLogin } from './util';

const routes = [
  // {
  //   path: '/',
  //   meta: {
  //     title: '个人中心',
  //   },
  //   redirect: '/personal'
  // },
  {
    path: '/middle',
    name: 'middle',
    component: MiddlePage
  },
  // {
  //   path: '/login',
  //   component: Login
  // },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: Login2
  },
  {
    path: '/rights',
    name: 'rights',
    meta: {
      title: '权益激活',
    },
    component: Rights
  },
  {
    path: '/drugActive',
    name: 'drugActive',
    meta: {
      title: '普药特药权益升级',
    },
    component: DrugActive
  },
  
  {
    path: '/rightsScane',
    name: 'rightsScane',
    meta: {
      title: '权益激活',
    },
    component: RightsScane
  },
  {
    path: '/rightsBaiSui',
    name: 'rightsBaiSui',
    meta: {
      title: '百岁工程权益激活',
    },
    component: RightsBaiSui
  },
  {
    path: '/rightsBaiSuiPlus',
    name: 'rightsBaiSuiPlus',
    meta: {
      title: '百岁工程权益升级',
    },
    component: RightsBaiSuiPlus
  },
  {
    path: '/rightsActive',
    name: 'rightsActive',
    meta: {
      title: '权益激活',
    },
    component: RightsActive
  },
  {
    path: '/rightsPerson',
    name: 'rightsPerson',
    meta: {
      title: '我的权益',
    },
    component: RightsPerson
  },
  {
    path: '/rightsPersonAdd',
    name: 'rightsPersonAdd',
    meta: {
      title: '添加家人',
    },
    component: RightsPersonAdd
  },
  {
    path: '/rightsPersonList',
    name: 'rightsPersonList',
    meta: {
      title: '我的卡包',
    },
    component: RightsPersonList
  },
  {
    path: '/rightsDetail',
    name: 'rightsDetail',
    meta: {
      title: '权益详情',
    },
    component: RightsDetail
  },
  {
    path: '/progress',
    name: 'progress',
    meta: {
      title: '权益预约',
    },
    component: ProgressComponent
  },
  {
    path: '/progressStep2',
    name: 'progressStep2',
    meta: {
      title: '权益预约',
    },
    component: ProgressComponent2
  },
  {
    path: '/progressStep2Base',
    name: 'progressStep2Base',
    meta: {
      title: '权益预约',
    },
    component: ProgressComponent2Base
  },
  {
    path: '/progressStep2GP96',
    name: 'progressStep2GP96',
    meta: {
      title: '权益预约',
    },
    component: ProgressComponent2GP96
  },
  {
    path: '/progressStep3',
    name: 'progressStep3',
    meta: {
      title: '权益预约',
    },
    component: ProgressComponent3
  },
  {
    path: '/progressStep3Base',
    name: 'progressStep3Base',
    meta: {
      title: '权益预约',
    },
    component: ProgressComponent3Base
  },
  {
    path: '/progressStep4',
    name: 'progressStep4',
    meta: {
      title: '预约完成',
    },
    component: ProgressComponent4
  },
  {
    path: '/appointmentDetail',
    name: 'appointmentDetail',
    meta: {
      title: '预约完成',
    },
    component: AppointmentDetail
  },
  {
    path: '/appointmentList',
    name: 'appointmentList',
    meta: {
      title: '我的预约',
    },
    component: AppointmentList
  },
  {
    path: '/personal',
    name: 'personal',
    meta: {
      title: '个人中心',
    },
    component: Personal
    
  },
  {
    path: '/content1',
    name: 'content1',
    meta: {
      title: '特药项目介绍',
    },
    component: Content1
  },
  {
    path: '/content2',
    name: 'content2',
    meta: {
      title: '国寿国药全面战略合作',
    },
    component: Content2
  },
  {
    path: '/serverPdfBase',
    name: 'serverPdfBase',
    meta: {
      title: '服务手册',
    },
    component: serverPdfBase
  },
  {
    path: '/serverPdfBasePlus',
    name: 'serverPdfBasePlus',
    meta: {
      title: '服务手册',
    },
    component: serverPdfBasePlus
  },
  {
    path: '/serverPdfTcrt',
    name: 'serverPdfTcrt',
    meta: {
      title: '服务手册',
    },
    component: serverPdfTcrt
  },
  {
    path: '/serverPdfPlus',
    name: 'serverPdfPlus',
    meta: {
      title: '服务手册',
    },
    component: serverPdfPlus
  },
  {
    path: '/serverPdfZhizhen',
    name: 'serverPdfZhizhen',
    meta: {
      title: '服务手册',
    },
    component: serverPdfZhizhen
  },
  {
    path: '/serverPdf320Zhizhen',
    name: 'serverPdf320Zhizhen',
    meta: {
      title: '服务手册',
    },
    component: serverPdf320Zhizhen
  },
  {
    path: '/serverPdfPlusZhizhen',
    name: 'serverPdfPlusZhizhen',
    meta: {
      title: '服务手册',
    },
    component: serverPdfPlusZhizhen
  },
  {
    path: '/serverPdfBaiSui',
    name: 'serverPdfBaiSui',
    meta: {
      title: '百岁工程服务手册',
    },
    component: serverPdfBaiSui
  },
  {
    path: '/serverPdf310Zhizhen',
    name: 'serverPdf310Zhizhen',
    meta: {
      title: '服务手册',
    },
    component: serverPdf310Zhizhen
  },
  
  {
    path: '/serverPdf10Zhizhen',
    name: 'serverPdf10Zhizhen',
    meta: {
      title: '服务手册',
    },
    component: serverPdf10Zhizhen
  },
  {
    path: '/productShow',
    name: 'productShow',
    meta: {
      title: '特药权益介绍',
    },
    component: ProductShow
  },
  {
    path: '/showPdf',
    name: 'showPdf',
    meta: {
      title: '特药权益介绍',
    },
    component: showPdf
  },
  {
    path: '/settleIndex',
    name: 'settleIndex',
    meta: {
      title: '个人结算业务支付',
    },
    component: settleIndex
  },
  {
    path: '/settleBaiSui',
    name: 'settleBaiSui',
    meta: {
      title: '结算业务支付',
    },
    component: settleBaiSui
  },
  {
    path: '/settleHuZhao',
    name: 'settleHuZhao',
    meta: {
      title: '结算业务支付',
    },
    component: settleHuZhao
  },
  {
    path: '/settleHuLi',
    name: 'settleHuLi',
    meta: {
      title: '结算业务支付',
    },
    component: settleHuLi
  },
  {
    path: '/settleYaoWuYou',
    name: 'settleYaoWuYou',
    meta: {
      title: '结算业务支付',
    },
    component: settleYaoWuYou
  },
  {
    path: '/paysuccess',
    name: 'paysuccess',
    meta: {
      title: '支付结果',
    },
    component: paysuccess
  },
  {
    path: '/payWxAlert',
    name: 'payWxAlert',
    meta: {
      title: '支付提示',
    },
    component: payWxAlert
  },
  {
    path: '/meetingReport',
    name: 'meetingReport',
    meta: {
      title: '参会登记',
    },
    component: meetingIndex
  },
  {
    path: '/meetingSuccess',
    name: 'meetingSuccess',
    meta: {
      title: '参会登记成功',
    },
    component: meetingSuccess
  },
  
  {
    path: '*',
    redirect: '/personal'
  },
  // {
  //   name: 'user',
  //   component: () => import('../views/user'),
  //   meta: {
  //     title: '会员中心'
  //   }
  // },
  // {
  //   name: 'cart',
  //   component: () => import('../views/cart'),
  //   meta: {
  //     title: '购物车'
  //   }
  // },
  // {
  //   name: 'goods',
  //   component: () => import('../views/goods'),
  //   meta: {
  //     title: '商品详情'
  //   }
  // }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  // const query = this.$route.query;
  const query = to.query;
  let publicType = localStorage.getItem('publicType');
  let tcrt = localStorage.getItem('tcrt');
  tcrt = query.tcrt || tcrt || 'false';
  publicType = query.publicType || publicType || '3'; // 1 - 现有逻辑， 2 - 带有家庭版逻辑
  localStorage.setItem('tcrt', tcrt);
  localStorage.setItem('publicType', publicType);
  if(to.path == '/settleIndex'){
    if(query.payType != null){
        localStorage.removeItem('code');
        localStorage.setItem('payeeType', parseInt(query.payType) + 1);
    }
    if(query.orgId != null){
      localStorage.setItem('orgId', query.orgId);
    }
    
  }

  let userMobile = query.userPhone;
  if(userMobile != null){
    localStorage.setItem('userMobile', userMobile);
  }

  let activationCode = query.activationCode;
  if(activationCode != null){
    localStorage.setItem('activationCode', activationCode);
  }

  if(to.path == '/settleBaiSui' || to.path == '/settleHuZhao' || to.path == '/settleHuLi' || to.path == '/settleYaoWuYou'){
    if(query.payType != null){
      localStorage.removeItem('code');
      localStorage.setItem('payeeType', parseInt(query.payType) + 1);
    }
    if(query.wxCode != null){
      localStorage.setItem('code', query.wxCode);
    }
    if(query.orgId != null){
      localStorage.setItem('orgId', query.orgId);
    }
  }

  let code = query.code || localStorage.getItem('code');
  let wechat = isWehat();
  let token = localStorage.getItem('token');
  let fPath = to.fullPath;
  fPath = fPath.slice(1);

  
  console.log("code===" + query.code);
  if (wechat) {
    console.log();
    if (!code) {
      //let httpUrl = 'http://www-test.healthmore.net';
      //let httpUrl = 'http://127.0.0.1:8080';
      let httpUrl = 'https://www.healthmore.net';
      let appId = 'wx0a96e693da6da47b'; // 1 - 生产环境
      //let appId = 'wxa8795af1a3a9ca7b'; //测试
      if (publicType == 2) {
        appId = 'wxc79e316104cf4898'; // 2 生产
        //appId = 'wxbe9cab0b8fcfd792'; //测试
      }
      if (publicType == 4) {
        appId = 'wxb0ea01d4fd25cc75';
      }
      // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0a96e693da6da47b&redirect_uri=http://127.0.0.1:8081/?url=${encodeURI(fPath)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${httpUrl}?url=${encodeURI(fPath)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
    } else {
      localStorage.setItem('code', code);
      
      let fullPath = query.url || to.fullPath;

      console.log('fullPath', to.path)
      // 权益页，不拦截
      if (to.path == '/rights' || to.path == '/rightsScane' || to.path == '/settleBaiSui' || to.path == '/settleHuZhao' || to.path == '/settleHuLi' || to.path == '/settleYaoWuYou' || to.path == '/rightsBaiSui' || to.path == '/rightsBaiSuiPlus' || to.path == '/drugActive' || to.path == '/content1' || to.path == '/content2' || to.path == '/productShow' || to.path == '/settleIndex' || to.path == '/meetingReport' || to.path == '/meetingSuccess' || to.path == '/paysuccess') {
        next();
        return;
      }
      
      
      let url = fullPath;
      if (to.query.code) {
        url = fullPath.replace('/personal?url=', '');
      }

      console.log('to', url)
      if (url.search(/rights\?/) > -1 || url.search(/rightsScane\?/) > -1 || url.search(/settleBaiSui\?/) > -1 || url.search(/settleHuZhao\?/) > -1 || url.search(/settleHuLi\?/) > -1 || url.search(/settleYaoWuYou\?/) > -1 || url.search(/rightsBaiSui\?/) > -1 || url.search(/rightsBaiSuiPlus\?/) > -1 || url.search(/drugActive\?/) > -1 || url.search(/content1/) > -1 || url.search(/content2/) > -1 || url.search(/productShow\?/) > -1 || url.search(/settleIndex\?/) > -1 || url.search(/meetingReport\?/) > -1 || url.search(/meetingSuccess/) > -1 || url.search(/paysuccess\?/) > -1){
        url = url.replace('&payType=0', '');
        console.log('进入', url)
        next({
          path: url
        });
        return;
      }

      let userPhone = localStorage.getItem('userMobile');
      console.log('userPhone', userPhone);
      if(!token && to.name == 'personal' && userPhone != null){
        console.log('userPhone', '进来了')
        let url = '';
        const res = await onMobileLogin(userPhone);
        if (res.code == 200) {
          localStorage.setItem('token', res.data.token);
          url = fullPath.replace('/personal?url=', '');
        } else if (res.code == 1007) {
          url = '/login';
        }
        localStorage.removeItem('userMobile');
        next({
          path: url
        })
        return;
      }

      if (!token && to.name != 'login') {
        let url = '';
        console.log('code', code);
        const res = await onLogin(code, fullPath);
        if (res.code == 200) {
          localStorage.setItem('token', res.data.token);
          url = fullPath.replace('/personal?url=', '');
        } else if (res.code == 1004) {
          // 1004 需要注册
          url = '/login';
        }
        console.log(444)
        next({
          path: url
        })
      }
      next();
    }
  } else {
    let fullPath1 = to.fullPath;
    console.log('fullpATH1', fullPath1)
    
    let url1 = '';
    if(fullPath1.search(/\/settleIndex\?/) > -1 || fullPath1.search(/\/settleBaiSui\?/) > -1 || fullPath1.search(/\/settleHuZhao\?/) > -1 || fullPath1.search(/\/settleHuLi\?/) > -1 || fullPath1.search(/\/settleYaoWuYou\?/) > -1){
      url1 = '/payWxAlert';
      next({
        path: url1
      });
      return ;
    }

    let userPhone = query.userPhone
    console.log('userPhone', userPhone);
    if(userPhone != null){
      const res = await onMobileLogin(userPhone);
      if (res.code == 200) {
        localStorage.setItem('token', res.data.token);
      } else if (res.code == 1007) {
        // 1004 需要注册
        url1 = '/login';
      }
      console.log('fullpATH', url1)
      next({
        path: url1
      })
      return ;
    }

    if (!token && to.name != 'login') {
        let fullPath = to.fullPath;
        let url = '';
        localStorage.setItem('redirectUrl', fullPath);
        console.log('fullpATH', fullPath)
        if (fullPath.search(/\/rights\?/) > -1 || fullPath.search(/\/rightsScane\?/) > -1 || fullPath.search(/\/rightsBaiSui\?/) > -1 || fullPath.search(/\/rightsBaiSuiPlus\?/) > -1 || fullPath.search(/\/drugActive\?/) > -1  || fullPath.search(/\/content1/) > -1 || fullPath.search(/\/content2/) > -1 || fullPath.search(/\/productShow\?/) > -1 || fullPath.search(/\/meetingReport\?/) > -1 || fullPath.search(/\/meetingSuccess/) > -1 || fullPath.search(/\/paysuccess\?/) > -1){
            url = fullPath;
            console.log('4')
            next();
            return;
        }else {
            // 1004 需要注册
            url = '/login';
            console.log('5')
        }
        next({
          path: url
        });
    }
    next();
  }
});
// router.beforeEach((to, from, next) => {
//   const title = to.meta && to.meta.title;
//   if (title) {
//     document.title = title;
//   }


  
//   next();
// });

export {
  router
};