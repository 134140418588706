<template>
    <div class="right-person">
        <!-- 有权益 -->
        <div v-if="rightsVisable" class="bg">
            <div v-for="(item, itemIndex) in rightsList" :key="itemIndex">
                <div class="header">
                    <div class="banner">
                        <img :src="item.productCardImgUrl" />
                        <img v-if="item.productName!='HPV权益卡'" class="download" src="@/assets/download.png" />
                        <span v-if="item.productName!='HPV权益卡'" class="banner-card">{{ item.privilegeState | onFilterStatus }}</span>
                        <p v-if="item.productName!='HPV权益卡'" :class="[{'banner-bottom': true}]">
                            <span v-if="item.productName != '个人1年长续版'" >权益人：{{ item.personName }}</span>
                            <span v-if="item.productName != '个人1年长续版'" >最长有效期至：{{ item.privilegeExpire }}</span>
                            <span v-if="item.productName = '个人1年长续版'" style="color: #000;" >权益人：{{ item.personName }}</span>
                            <span v-if="item.productName = '个人1年长续版'" style="color: #000;" >最长有效期至：{{ item.privilegeExpire }}</span>
                        </p>
                    </div>
                    <p class="text-center" :style="publicType == '3' ? 'color:#12cd9f;' : 'color:#005DCC;'">
                        <span @click="onShow(item,itemIndex)">查看详情</span>
                    </p>
                     <p class="text-center" :style="publicType == '3' ? 'color:#12cd9f;' : 'color:#005DCC;'">
                        <span v-if="item.remark.serverPdf != null && !isTransfer" @click="onShowPdf(item.remark.serverPdf)">《服务手册》</span>
                        <span v-if="item.remark.drugListUrl != null" @click="onShowDrug(item.remark.drugListUrl)" >《药品目录》</span>
                        <span v-if="item.remark.renewalText != null" @click="onShowDrug(item.remark.renewalText)" >《续期约定》</span>
                     </p>
                </div>
                <div class="notice" v-if="item.privilegeList[0].privilegeState == 4">
                    <span class="remind">提醒</span>您的权益卡处于等待期 {{ item.startWaitTime }} ~ {{ item.endWaitTime}}
                </div>
                <!-- 家庭版，展示添加家庭成员信息 -->
                <div class="family" v-if="item.productCategory == 2">
                    <div class="family-header">
                        <p>我的家庭保障</p>
                        <span :class="publicType == '3' ? 'right-yishu' : 'right'" @click="onAddFamily"><van-icon name="manager-o" />去添加</span>
                    </div>
                    <div class="family-body" v-for="item in personOtherFamily" :key="item.personId">
                        <p class="family-body_relation" :style="{background: 'linear-gradient(315deg, rgb(247, 98, 46) 0%, rgb(255, 161, 75) 100%)'}">{{ item.relationType | onFilterRelative}}</p>
                        <p class="family-body_name">{{item.personName}}</p>
                    </div>
                </div>
                <h2>权益展示
                    
                    <div class="right">
                        <van-button v-if="(publicType == 3 || publicType == 2 || publicType == 4) && !isChenXiTeYao"  style="float:left; margin-left: 18px;background-color: #12cd9f;border: 0.02667rem solid #12cd9f;" @click="applyMethod(item.privilegeList[0].privilegeState)"  :class="publicType == 3 ? 'btn-mini-green' : 'btn-mini'" size="mini" round type="info" >服务发起</van-button>
                        <van-button v-if="isUpdatePlus"  style="float:left;background-color: #12cd9f;border: 0.02667rem solid #12cd9f;" @click="showUpdate()"  :class="publicType == 3 ? 'btn-mini-green' : 'btn-mini'" size="mini" round type="info" >权益升级</van-button>
                        <van-button v-if="isShowDrugButton"  style="float:left;background-color: #12cd9f;border: 0.02667rem solid #12cd9f;" @click="showDrug()"  :class="publicType == 3 ? 'btn-mini-green' : 'btn-mini'" size="mini" round type="info" >普/慢药升级</van-button>
                        <van-button v-if="isThreeProduct"  style="float:left;background-color: #12cd9f;border: 0.02667rem solid #12cd9f;" @click="showActivation()"  :class="publicType == 3 ? 'btn-mini-green' : 'btn-mini'" size="mini" round type="info" >多卡激活</van-button>

                    </div>
                </h2>
                <!-- 可循环 -->
                <div class="right-person_item">
                    <div class="right-person_item__content">
                        <ul>
                            <li v-for="(listItem, listIndex) in item.privilegeList" :key="listIndex" :class="[{'unclick': [3].includes(listItem.privilegeState)}]">
                                <div class="flex">
                                    <img :src="listItem.privilegeImgUrl" />
                                    <div class="padding-left-sm flex-1">
                                        <div class="flex flex-between">
                                            <p class="title">{{ listItem.privilegeName }}</p>
                                            <p class="padding-left-sm text-right">

                                                <van-button v-if="(listItem.privilegeType == 3 || listItem.privilegeType == 4) && !isChenXiTeYao" style="background-color: #12cd9f;border: 0.02667rem solid #12cd9f;" class="btn-mini" size="mini" round type="info" @click="onHandlePrivew(listItem, listItem.privilegeState, itemIndex)">前往购药</van-button>
                                                <van-button v-if="isChenXiTeYao" style="background-color: #12cd9f;border: 0.02667rem solid #12cd9f;" class="btn-mini" size="mini" round type="info" @click="onHandlePrivew(listItem, listItem.privilegeState, itemIndex)">服务发起</van-button>

                                                <van-button v-if="publicType == 1" class="btn-mini" size="mini" round type="info" @click="onHandlePrivew(listItem, listItem.privilegeState, itemIndex)">{{ listItem.privilegeState | onItemFilterStatus }}</van-button>
                                            </p>
                                        </div>
                                        <div class="desc-info">
                                            <dl v-for="(descItem, descIndex) in listItem.descList" :key="descIndex">
                                                <dt v-if="(item.remark.publicType == 1 || item.productName.includes('car-T'))" class="flex flex-column-center">
                                                    <img class="dot" src="@/assets/dot.png" />
                                                    <span>{{ descItem.privilegeDesc }}</span>
                                                </dt>
                                                <dd v-if="descItem.privilegeListDesc.length > 0" :style="publicType == '3' ? 'color:#12cd9f;' : 'color:#005DCC;'">
                                                    <span v-for="dItem in descItem.privilegeListDesc" :key="dItem">{{dItem}}</span>
                                                </dd>
                                            </dl>
                                        </div>
                                        <!-- <p class="person-date">有效期：{{ listItem.privilegeExpire }}</p> -->
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- 无权益 -->
        <div v-else>
            <div class="right-empty" v-if="!loading">
                <van-empty image-size="200px">
                    <div slot="description" class="empty-bottom-text text-center">
                       <p>您暂无此权益</p>
                       <p>请激活权益后再次尝试</p>
                       <!--<p>请激活权益后再次尝试</p>-->
                    </div>
                </van-empty>
            </div>
        </div>
        <van-popup
                        v-model="show"
                        round
                        overlay-class="info-mask"
                        closeable
                        close-icon="close">
                        <div class="info-mask_content">
                            <h4 class="text-center">详情</h4>
                            <p><span>姓名</span><span>{{ personInfo.personName }}</span></p>
                            <p><span>证件号</span><span>{{ personInfo.credentialsNumber }}</span></p>
                            <p><span>手机号</span><span>{{ personInfo.personPhone }}</span></p>
                            <p><span>首次激活</span><span>{{ personInfo.createTime }}</span></p>
                            <p><span>保障开始时间</span><span>{{ personInfo.startTime }}</span></p>
                            <p><span>保障结束时间</span><span>{{ personInfo.privilegeExpire }}</span></p>
                            <p><span>保障期限</span><span>{{ remark.insuredPeriod }}</span></p>
                            <p><span>用药期</span><span>{{ remark.drugPeriod }}</span></p>
                        </div>
                    </van-popup>
                    <!-- 内容 -->
    <van-popup
        v-model="showFlag"
        round
        closeable
        position="bottom"
        close-icon="close"
        :style="{ height: '70%' }">
        <div class="text-dialog flex flex-column" >
            <div class="text-content flex-1" v-html="currentAgreement"></div>
        </div>
    </van-popup>
    <van-popup
        v-model="showOverage"
        round
        overlay-class="info-mask"
        closeable
        close-icon="close">
        <div class="info-mask_content">
            <h4 class="text-center">温馨提示</h4>
            <p>尊敬的客户您好，您是超龄用户，请拨打客服电话申请服务。</p>
            <p v-if="publicType =='3' || publicType =='2' || publicType =='4'">
               <a class="tel_phone" :href="'tel:' + 4009028321">400-9028-321</a>
            </p>

        </div>
    </van-popup>
    <van-popup
        v-model="showUpdatePlus"
        round
        overlay-class="info-mask"
        closeable
        close-icon="close">
        <div class="info-mask_content">
            <h4 class="text-center">特药2.0权益升级</h4>
            <van-field v-model="plusCode" label="激活码" placeholder="请输入激活码" :onkeyup="plusCode = plusCode.replace(/\s+/g,'')" />
            <van-button type="info" @click="sumbitPlus()"  style="margin-left:35%;"  size="small" class="btn-mini-green">确认升级</van-button>
        </div>
    </van-popup>
        <van-popup
        v-model="showDrugPlus"
        round
        overlay-class="info-mask"
        closeable
        close-icon="close">
        <div class="info-mask_content">
            <h4 class="text-center">普药/特药权益升级</h4>
            <van-field v-model="drugCode" label="激活码" placeholder="请输入激活码" :onkeyup="drugCode = drugCode.replace(/\s+/g,'')" />
            <van-button type="info" @click="sumbitDrug()"  style="margin-left:35%;"  size="small" class="btn-mini-green">确认升级</van-button>
        </div>
    </van-popup>
     <van-popup
        v-model="showBaiSuiPlus"
        round
        overlay-class="info-mask"
        closeable
        close-icon="close">
        <div class="info-mask_content">
            <h4 class="text-center">百岁工程升级</h4>
            <van-field v-model="baiSuiCode" label="激活码" placeholder="请输入激活码" :onkeyup="baiSuiCode = baiSuiCode.replace(/\s+/g,'')" />
            <van-button type="info" @click="sumbitBaiSui()"  style="margin-left:35%;"  size="small" class="btn-mini-green">确认升级</van-button>
        </div>
    </van-popup>
    <van-popup
        v-model="showActivationFlag"
        round
        overlay-class="info-mask"
        closeable
        close-icon="close">
        <div class="info-mask_content">
            <h4 class="text-center">三年期权益累加激活</h4>
            <div v-for="(item, index) in activeCodeArr" :key="index">
                <van-field v-model="activeCodeArr[index]" label="激活码" placeholder="请输入激活码" :onkeyup="activeCodeArr[index] = activeCodeArr[index].replace(/\s+/g,'')">
                    <template #button>
                        <van-icon class="three-button" v-if="index < activeCodeMaxNum - 1" @click="onHandleAddActiveCode" name="add-o" />
                        <van-icon class="three-button" v-if="activeCodeArr.length !== 1" @click="onHandleDeleteActiveCode(index)" name="delete-o" />
                    </template>
                </van-field>
            </div>
            
            <van-button type="info" @click="sumbitActivation"  style="margin-left:35%;"  size="small" class="btn-mini-green">确认累加激活</van-button>
        </div>
    </van-popup>
    <van-popup
        v-model="showFree"
        round
        overlay-class="info-mask-free"
        closeable
        close-icon="close">
        <div class="info-mask_content" style="width: 320px;height:180px;">
            <h4 class="text-center">🧧 免费赠送权益🧧</h4>
            <p>恭喜您幸运获得[9月VIP礼遇]专属权益！</p>
            <p style="margin-top:25px;font-size:26px;margin-left:30px;color:red;">中医权益保障一份</p>
        </div>
    </van-popup>
    <van-overlay :show="showPlus" @click="showPlus = false" />
    </div>
    
</template>
<script>
import { Toast } from 'vant';
import { onFetchRightsByUser, onFetchDrugs, onAddPersonFamily, selUserPrivilegeDetail, updatePlusPlugin, activationThree,updateDrugPlugin,updateBaiSuiPlugin  } from '@/api';
export default {
    name: 'rightPersons',
    data() {
        return {
            activeCodeArr: [''], // 激活码个数
            activeCodeMaxNum: 3, // 激活码最大数量
            loading: true, // loading页面
            show: false,
            rightsVisable: false,
            rightsList: [],
            publicType: null,
            toastShow: false, // 是否展示药品种类弹窗
            personItem: null, // 受益人信息
            personShow: false, // 添加受益人
            personInfo: '',
            remark:'',
            personName: '',
            credentialsNumber: '',
            relationType: '2',
            personId: '',
            drugList: [], // 药品列表
            personPrivilegeId: '',
            activationId: '',
            personOtherFamily: [], // 受益人家人
            currentAgreement:'',
            showFlag: false,
            overageFlag: false,
            showOverage: false,
            showUpdatePlus: false,
            showDrugPlus: false,
            showBaiSuiPlus: false,
            showFree: false,
            showActivationFlag: false,
            isTransfer: false,
            isUpdatePlus: false,
            isShowDrugButton: false,
            isThreeProduct: false,
            isShowBaiSuiButton: false,
            isPlus: false,
            privilegeJumpUrl: '',
            plusCode:'',
            drugCode:'',
            baiSuiCode:'',
            submitPlusFlag: false,
            submitBaiSuiPlusFlag: false,
            submitActivationFlag: false,
            showPlus: false,
            cityCode: '',
            productMark: '',
            orgId: '',
            isChenXiTeYao: false,
            activationCardNumber: ''
        }
    },
    created() {
        this.publicType = localStorage.getItem('publicType');
        this.personPrivilegeId = this.$route.query.personPrivilegeId
        this.init();
    },
    filters: {
        // 本人关系
        onFilterRelative(val) {
            let msg = '';
            switch(val) {
                case 1:
                    msg = '本人';
                    break;
                case 2:
                    msg = '配偶';
                    break;
                case 3:
                    msg = '子女';
                    break;
                case 4:
                    msg = '父母';
                    break;
                default:
                    msg = '子女';
            }
            return msg;
        },
        // 状态
        onFilterStatus(val) {
            let msg = '';
            switch(val) {
                case 1:
                    msg = '已生效';
                    break;
                case 2:
                    msg = '已失效';
                    break;
                case 3:
                    msg = '已使用';
                    break;
                case 4:
                    msg = '等待期';
                    break;
                case 5:
                    msg = '未生效';
                    break;
            }
            return msg;
        },
        // 状态
        onItemFilterStatus(val) {
            let msg = '';
            switch(val) {
                case 1:
                    msg = '立即预约';
                    break;
                case 2:
                    msg = '已失效';
                    break;
                case 3:
                    msg = '已使用';
                    break;
                case 4:
                    msg = '等待期';
                    break;
                case 5:
                    msg = '未生效';
                    break;
            }
            return msg;
        }
    },
    methods: {
        // 添加激活码
        onHandleAddActiveCode() {
        	if (this.activeCodeArr.length >= this.activeCodeMaxNum) return false;
            this.activeCodeArr.push('');
        },
        // 删除激活码
        onHandleDeleteActiveCode(index) {
            if (this.activeCodeArr.length === 1) return false;
            this.activeCodeArr.splice(index, 1);
        },
        // 添加家人
        onAddFamily() {
            this.$router.push(`/rightsPersonAdd?personPrivilegeId=${this.personPrivilegeId}&isPlus=${this.isPlus}`)
        },
        async onHandleShowList(item) {
            console.log('item', item);
            try {
                const res = await onFetchDrugs({
                    privilegeId: item.privilegeId
                });
                console.log('res', res);
                if (res.code == 200) {
                    this.drugList = res.data;
                }
            } catch(err) {
                console.log(err);
            }
            this.toastShow = true;
        },
        onShowDrug(index) {
            this.currentAgreement = index;
            this.showFlag = true;
        },
        async onSubmit(values) {
          console.log('submit', values);
          try {
            const res = await onAddPersonFamily({
                personPrivilegeId: this.personItem.personPrivilegeId,
                personName: this.personName,
                credentialsNumber: this.credentialsNumber,
                relationType: this.relationType
            });
            if (res.code == 200) {
                Toast('添加成功!');
                setTimeout(() => {
                    this.personShow = false;
                    this.init();
                }, 2000);
            } else {
                Toast(res.msg);
            }
          } catch(err) {
            console.log('err', err);
            Toast(err);
          }
        },
        async sumbitPlus(){
            if(this.submitPlusFlag){
                return false;
            }
            this.submitPlusFlag = true;
            try {
                const res = await updatePlusPlugin({
                personPrivilegeId: this.personPrivilegeId,
                activationCode: this.plusCode
            });
            if (res.code == 200) {
                this.showPlus = true;
                Toast('权益升级成功!');
                this.$router.push('/rightsPerson?personPrivilegeId=' + res.data);
                this.personPrivilegeId = res.data;
                this.init();
                this.showUpdatePlus = false;
                this.submitPlusFlag = false;

            } else {
                Toast(res.msg);
                this.submitPlusFlag = false;
            }
          } catch(err) {
            console.log('err', err);
            Toast(err);
            this.submitPlusFlag = false;
          }
        },
        async sumbitDrug(){
            if(this.submitPlusFlag){
                return false;
            }
            this.submitPlusFlag = true;
            try {
                const res = await updateDrugPlugin({
                personPrivilegeId: this.personPrivilegeId,
                activationCode: this.drugCode
            });
            if (res.code == 200) {
                this.showPlus = true;
                Toast('权益升级成功!');
                this.$router.push('/rightsPerson?personPrivilegeId=' + res.data);
                this.personPrivilegeId = res.data;
                this.init();
                this.showDrugPlus = false;
                this.submitPlusFlag = false;

            } else {
                Toast(res.msg);
                this.submitPlusFlag = false;
            }
          } catch(err) {
            console.log('err', err);
            Toast(err);
            this.submitPlusFlag = false;
          }
        },
        async sumbitBaiSui(){
            if(this.submitBaiSuiPlusFlag){
                return false;
            }
            this.submitBaiSuiPlusFlag = true;
            try {
                const res = await updateBaiSuiPlugin({
                personPrivilegeId: this.personPrivilegeId,
                activationCode: this.baiSuiCode
            });
            if (res.code == 200) {
                this.showPlus = true;
                Toast('百岁工程权益升级成功!');
                this.$router.push('/rightsPerson?personPrivilegeId=' + res.data);
                this.personPrivilegeId = res.data;
                this.init();
                this.showBaiSuiPlus = false;
                this.submitBaiSuiPlusFlag = false;

            } else {
                Toast(res.msg);
                this.submitBaiSuiPlusFlag = false;
            }
          } catch(err) {
            console.log('err', err);
            Toast(err);
            this.submitBaiSuiPlusFlag = false;
          }
        },
        async sumbitActivation(){
            if(this.submitActivationFlag){
                return false;
            }
            this.submitActivationFlag = true;
            try {
                const res = await activationThree({
                personPrivilegeId: this.personPrivilegeId,
                activationId: this.activationId,
                activationCodeList: this.activeCodeArr
            });
            if (res.code == 200) {
                Toast('三年期权益累加激活成功，请前往我的权益查看!');
                this.showActivationFlag = false;
                this.submitActivationFlag = false;
            } else {
                Toast(res.msg);
                this.submitActivationFlag = false;
            }
          } catch(err) {
            console.log('err', err);
            Toast(err);
            this.submitActivationFlag = false;
          }
        },
        // 添加权益人
        addPerson(item) {
            this.personItem = item;
            this.personShow = true;
        },
        applyMethod(privilegeState){
            if(privilegeState == 4){
                Toast('您的权益还处于等待期内，无法发起预约');
                return;
            }
            if(privilegeState == 2){
                Toast('您的权益已过期，无法发起预约');
                return;
            }
            if(privilegeState == 5){
                Toast('您的权益未生效，无法发起预约');
                return;
            }
           
            if(this.activationCardNumber != '1883880015957' && this.activationCardNumber != '1885880006087' && this.activationCardNumber != '1883880084393'){
                if(this.orgId != null && (this.orgId == '306' || this.orgId == '503' || this.orgId == '452' || this.orgId == '420' || this.orgId == '552' || this.orgId == '705' || this.orgId == '707' || this.orgId == '1076' )){
                    Toast('暂时无法提供服务');
                    return;
                }
            }
            //迪庆 特药无法提供服务
            if(this.cityCode == '533400' && this.productMark == '0'){
                Toast('暂时无法提供服务');
                return;
            }



             

            if(this.overageFlag){
                this.showOverage = true;
                return;
            }
            window.location.href = this.rightsList[0].privilegeList[0].privilegeUrl;
            return;
        },
        showUpdate(){
            this.showUpdatePlus = true;
            return;
        },
        showDrug(){
            this.showDrugPlus = true;
            return;
        },
        showBaiSui(){
            this.showBaiSuiPlus = true;
            return;
        },
        showActivation(){
            this.showActivationFlag = true;
            return;
        },
        onShowPdfInSite(url) {
            this.$router.push('/showPdf?url=' + encodeURI(url));
        },
        onShowPdf(url){

            window.location.href = url + "?personPrivilegeId=" + this.personPrivilegeId + "&publicType=" + this.publicType;
            // window.open(url);
        },
        // 切换家庭人
        setActive(item, index, listIndex) {
            console.log('item',item,  index, listIndex);
            this.personFamily = item;
            this.rightsList[index]['currentIndex'] = listIndex;
            console.log('this.rightsList', this.rightsList);
            this.$forceUpdate();
        },
        showPopup() {
            this.toastShow = true;
        },
        onShow(item,index) {
            this.personInfo = item;
            this.remark = item.remark;
            this.show = true;
        },
        // 获取权益
        async init() {
            this.loading = true;
            try {
                const res = await selUserPrivilegeDetail({
                    personPrivilegeId: this.personPrivilegeId
                });
                if (res.code == 200) {
                    this.rightsList = res.data;
                    this.rightsVisable = true;
                    //如果为宸汐特药服务公众号，则直接跳转至宸汐即可
                    if(false) {
                        let forPrivilegeList = this.rightsList[0].privilegeList;
                        forPrivilegeList.forEach((v, i) => {
                            if(v.isUrlPrivilege) {
                                window.location.href = v.privilegeUrl;
                                return;
                            }
                        })
                    }else {
                        // 受益人信息
                        this.personFamily = this.rightsList[0].personFamily[0];
                        // 受益人家人
                        this.personOtherFamily = this.rightsList[0].personFamily.slice(1);
                        this.overageFlag = this.rightsList[0].overageFlag;
                        this.isTransfer = this.rightsList[0].isTransfer;
                        this.isUpdatePlus = this.rightsList[0].isUpdatePlus;
                        this.isShowDrugButton = this.rightsList[0].isShowDrugButton;
                        this.isThreeProduct = this.rightsList[0].isThreeProduct;
                        this.isChenXiTeYao = this.rightsList[0].isChenXiTeYao;
                        this.activationCardNumber = this.rightsList[0].activationCardNumber;
                        this.isShowBaiSuiButton = this.rightsList[0].isShowBaiSuiButton;
                        this.activationId = this.rightsList[0].activationId;
                        this.cityCode = this.rightsList[0].cityCode;
                        this.productMark = this.rightsList[0].productMark;
                        if(this.rightsList[0].orgId != null){
                            this.orgId = this.rightsList[0].orgId;
                        }

                        this.isPlus = this.rightsList[0].isPlus;
                        this.showFree = this.rightsList[0].isAlertFree;
                        console.log('other', this.personOtherFamily);
                        // productCategory - 1 个人版， - 2 家庭版
                        // this.productCategory = this.rightsList[0].productCategory;
                        this.rightsList.forEach((v, i) => {
                            this.rightsList[i]['currentIndex'] = 0;
                        })
                    }
                } else if (res.code == 2014) {
                    // 无权益
                    this.rightsVisable = false;
                }
            } catch(err) {
                console.log('err', err);
            }
            this.loading = false;
            this.showPlus = false;
        },
        goPagePersonal(url) {
            this.$router.push(url);
        },
        // 回到激活页
        onGoPage(page) {
            if(this.publicType == 2) {
                this.$router.push({
                    name: page,
                    query: {
                        productCode: 'rainbowD',
                        publicType: 2
                    }
                });
            } else {
                this.$router.push({
                    name: page,
                    query: {
                        productCode: 'CAT1',
                        publicType: 1
                    }
                });
            }
        },
        // 使用权益
        onHandlePrivew(privilegeItem, flag, index) {
            if(flag == 4 && privilegeItem.privilegeType != 3 && privilegeItem.privilegeType != 4) {
                Toast('您的权益还处于等待期内，无法发起服务');
                return ;
            }
            if(flag == 2) {
                Toast('您的权益已过期，无法发起服务');
                return ;
            }
            if(flag == 3) {
                Toast('您的权益已使用完成，无法再次发起服务');
                return ;
            }
            let id = privilegeItem.privilegeId;

            

            if(privilegeItem.isUrlPrivilege){
                if(privilegeItem.privilegeType == 3){
                    window.location.href = privilegeItem.jumpUrl;
                }else{
                    window.location.href = privilegeItem.privilegeUrl;
                }
                return;
            } else{
                let currentIndex = this.rightsList[index]['currentIndex'];
                let personId = this.rightsList[index].personFamily[currentIndex].personId;
                let isApplyInform = this.rightsList[index].isApplyInform;
                localStorage.setItem('privilegeId', id);
                localStorage.setItem('personId', personId);
                if(isApplyInform) {
                    this.$router.push(`/rightsDetail?id=${id}&personId=${personId}`);
                } else {
                    let applyPageSkipUrl = this.rightsList[index].applyPageSkipUrl;
                    this.$router.push(applyPageSkipUrl + `?id=${id}&personId=${personId}`);
                }
                
            }
        }
    }
}
</script>
<style scoped lang="less">
::v-deep .van-radio-group--horizontal {
    display: flex;
    .van-radio--horizontal {
        flex: 1;
        font-size: 12px;
    }
}
.table-toast {
    position: relative;
    width: 310px;
    &_max {
        max-height: 300px;
        overflow-y: auto;
    }
    table {
        width: 100%;
        border-collapse:collapse;
        border:none;
        position: relative;
        &.absolute {
            tbody {
                display: none;
            }
        }
        td,
        th {
            padding: 5px;
            font-size: 12px;
            font-weight: normal;
        }
        th {
            padding: 10px 5px;
        }
        thead {
            background: #94d3f4;
            font-size: 16px;
            font-weight: 500;
        }
        tbody {
            tr {
                &:nth-child(even) {
                    background: #f2f2f2;
                }
            }
        }
    }
}
.add-person {
    padding: 20px;
    width: 300px;
}
.van-cell::after {
    border-bottom: 0;
}
.btn-options {
    button {
        margin: 0 10px;
    }
}

::v-deep .info-mask {
    background-color: rgba(0, 0, 0, .3) !important;
    &_content {
        width: 280px;
        padding: 10px 20px;
        h4 {
            margin: 0;
            padding: 10px;
            font-size: 18px;
        }
        p {
            margin-bottom: 10px;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
        }
    }
}
::v-deep .info-mask-free {
    background-color: rgba(0, 0, 0, .3) !important;
    &_content {
        width: 340px;
        padding: 10px 20px;
        h4 {
            margin: 0;
            padding: 10px;
            font-size: 18px;
        }
        p {
            margin-bottom: 10px;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
        }
    }
}
.right-person{
    color: #333;
    background: #f5f5f5;
    .bg {
        padding: 10px;
    }
    .header {
        font-size: 12px;
        .banner {
            width: 300px;
            margin: 0 auto;
            position: relative;
            color: #fff;
            img {
                width: 100%;
            }
            .download {
                width: 54px;
                position: absolute;
                left: 4px;
                top: 24px;
                z-index: 9;
            }
            &-card {
                position: absolute;
                left: 12px;
                top: 25px;
                z-index: 19;
            }
            p {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 28px;
                padding: 0 15px;
                display: flex;
                justify-content: space-between;
                &.banner-bottom {
                    width: 95%;
                    margin-left: 2.5%;
                    bottom: 18px;
                    padding: 7px 15px;
                    background-color: rgba(255, 255, 255, .3);
                }
            }
        }
        &> p {
            color: #12cd9f;
            font-weight: 500;
        }
        &.family {
            h2 {
                display: flex;
                align-items: center;
                i {
                    display:inline-block;
                    vertical-align:middle;
                    margin-right:10px;
                    width:4px;
                    height:20px;
                    background:#1989fa;
                    border-radius:2px;
                }
                span {
                    display: inline-block;
                    padding: 5px 10px;
                    font-size: 12px;
                    color: #fff;
                    background: #1989fa;
                    border-radius: 30px;
                    margin-left: 20px;
                }
            }
            .header-span {
                span {
                    display: inline-block;
                    padding: 5px 10px;
                    background: #ccc;
                    border-radius: 30px;
                    margin: 10px 10px 0 0;
                    &.active {
                        color: #fff;
                        background: #1989fa;
                    }
                }
            }
            .header-item {
                padding: 10px 0;
                &>div {
                    display: flex;
                    font-size: 14px;
                    line-height: 24px;
                    p {
                        flex: 1;
                    }
                }
            }
        }
    }
    .notice {
        background: rgba(252, 137, 64, 0.03);
        border-radius: 5px;
        padding: 5px;
        color: #FC8940;
        font-size: 12px;
        margin: 10px auto 0;
        display: flex;
        align-items: center;
        .remind {
            background: #FC8940;
            padding: 2px 5px;
            color: #fff;
            font-size: 10px;
            margin-right: 5px;
            border-radius: 2px;
            text-align: center;
        }
    }
    .btn-mini-green {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #12cd9f;
        border: 0.02667rem solid #12cd9f;
    }
    .family {
        margin: 10px 0;
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        position: relative;
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            position: relative;
            margin-bottom: 10px;
            .right {
                font-size: 12px;
                font-weight: normal;
                color: #005dcc;
            }
            .right-yishu {
                font-size: 12px;
                font-weight: normal;
                color: #12cd9f;
            }
        }
        &-body {
            display: inline-block;
            vertical-align: middle;
            margin: 0 13px 0 3px;
            text-align: center;
            &_relation {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                font-size: 12px;
                font-weight: 500;
                line-height: 40px;
                color: #fff;
            }
            &_name {
                font-size: 12px;
                color: #666;
            }
        }
        &-remark {
            margin-top: 10px;
            color: #005dcc;
            font-size: 12px;
            line-height: 16px;
        }
        &-remark-yishu {
            margin-top: 10px;
            color: #12cd9f;
            font-size: 12px;
            line-height: 16px;
        }
    }
    h2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        font-size: 18px;
        position: relative;
        padding: 0 10px;
        .right {
            font-size: 12px;
            font-weight: normal;
            color: #005dcc;
        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 10%;
            width: 4px;
            height: 80%;
            background: #1989fa;
        }
    }
    &_item {
        margin-bottom: 20px;
        &__title {
            background: #fff;
            border-radius: 10px 10px 0 0;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 10px;
            h4 {
                font-size: 16px;
            }
            .person-info {
                margin-top: 10px;
                img {
                    width: 17px;
                    height: 17px;
                }
            }
        }
        &__content {
            ul {
                li {
                    position: relative;
                    padding: 25px 10px 10px;
                    font-size: 12px;
                    color: grey;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    background: #fff;
                    &.unclick {
                        filter: grayscale(1);
                        opacity: 0.6;
                    }
                    .item-tag {
                        position: absolute;
                        left: 0;
                        top: 0;
                        border-radius: 10px 0 10px 0;
                        overflow: hidden;
                        font-size: 12px;
                        padding: 3px 8px;
                        color: #fff;
                        background-color: #1989fa;
                    }
                    img {
                        width: 64px;
                        height: 64px;
                    }
                    .title {
                        color: #333;
                        font-size: 14px;
                        font-weight: bold;
                        margin-bottom: 10px;
                        flex: 1;
                    }
                    .btn-mini {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    .btn-mini-green {
                        padding-left: 10px;
                        padding-right: 10px;
                        background-color: #12cd9f;
                        border: 0.02667rem solid #12cd9f;
                    }
                    .btn-mini-grayscale {
                        padding-left: 10px;
                        padding-right: 10px;
                        filter: grayscale(1);
                    }
                    .desc-info {
                        dl {
                            dt, 
                            dd {
                                margin-bottom: 5px;
                            }
                            dt {
                                font-size: 14px;
                                color: #FC8940;
                                img {
                                    margin-top: 0;
                                    margin-right: 5px;
                                    width: 5px;
                                    height: 5px;
                                }
                            }
                            dd {
                                padding: 3px 5px;
                                color: #005DCC;
                                span {
                                    &::after {
                                        content: '、';
                                    }
                                    background: #F3F8FF;
                                    &:last-child {
                                        &::after {
                                            content: '';
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .right-empty {
        padding: 40px;
        .empty-bottom-text {
            width: 200px;
            text-align: center;
            font-size: 14px;
            .active {
                color: #1989fa;
            }
        }
    }
}
.tel_phone {
    font-size: 25px;
    margin-left: 45px;
}
.three-button {
    font-size: 20px;
    margin-left: 5px;
    vertical-align: middle;
    color: #c8c9cc;
}
::v-deep .van-field__label {
    width: 50px;
}
</style>